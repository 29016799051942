import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikProps, FormikHelpers, FormikState } from 'formik';
import ClientService from '../../services/ClientService';
import Button, { ButtonType } from '../../components/shared/form-control/Button';
import { Heading, HeadingSize } from '../../components/shared/text/Heading';
import ClientManage from '../../components/clients/ClientManage';
import { ClientMaintainDto } from '../../models/Client';
import PageLoader from '../../components/shared/page-loader/PageLoader';
import { Roles } from '../../models/Role';
import { useTranslation } from 'react-i18next';
import useSchema from '../../schema';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import { BreadCrumbBackButton } from '../../components/shared/breadcumb/BreadCrumbBackButton';
import usePermissions from '../../hooks/permissions/usePermissions';
import { systemDefaultLanguageCode } from '../../types/Languages';
import { useCurrentClient } from '../../global-state/Clients';
import { useShallow } from 'zustand/react/shallow';
import { useInConsultantSpace } from '../../global-state/Workspace';

const INITIAL_VALUES: ClientMaintainDto = {
  client: {
    name: '',
    industry: '',
    size: '',
    accountNumber: '',
    tenantId: null,
    language: systemDefaultLanguageCode,
  },
};

const MaintainClientDtos: FC = () => {
  const [initialValues, setInitialValues] = useState<ClientMaintainDto>(INITIAL_VALUES);
  const schemas = useSchema();
  const { t } = useTranslation(['client-manage', 'client-list', 'form']);
  const resetFormRef = useRef<(nextState?: Partial<FormikState<ClientMaintainDto>> | undefined) => void | null>();

  const navigate = useNavigate();
  const [client, setClient] = useCurrentClient(useShallow((state) => [state.value, state.setValue]));
  const setInConsultantDashboard = useInConsultantSpace((x) => x.setValue);
  const hasPermission = usePermissions();

  useEffect(() => {
    setInConsultantDashboard(true);
    return () => {
      setInConsultantDashboard(false);
    };
  }, [setInConsultantDashboard]);

  const init = useCallback(async () => {
    if (client) {
      setInitialValues({
        client: {
          accountNumber: client.accountNumber || '',
          location: client.location,
          industry: client.industry,
          name: client.name,
          size: client.size,
          logoId: client.logoId,
          parentId: client.parentId,
          tenantId: client.tenantId,
          language: client.language,
        },
      });
    }
  }, [client]);

  useEffect(() => {
    init();
  }, [init]);

  const saveClient = (values: ClientMaintainDto, { setSubmitting }: FormikHelpers<ClientMaintainDto>) => {
    if (!client?.id) {
      return;
    }

    ClientService.updateClient(client.id, values.client)
      .then((res) => setClient(res))
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setSubmitting(false);
        resetFormRef.current && resetFormRef.current();
      });
  };

  const SaveButton = ({ formik, handleClick }: { formik: FormikProps<ClientMaintainDto>; handleClick: () => void }) => {
    const { dirty, isValid, isInitialValid, isSubmitting } = formik;

    const disabled = !hasPermission(Roles.ConsultantManager) || !dirty || !(isValid || isInitialValid) || isSubmitting;

    const disabledTitle = !dirty ? t('form:titles.no-changes') : isSubmitting ? t('form:titles.submitting') : t('form:titles.resolve-errors');

    const title = disabled ? disabledTitle : t('client-manage:titles.save-client');

    return (
      <Button data-cy="save-client" title={title} disabled={disabled} type={ButtonType.PRIMARY} onClick={handleClick}>
        {t('client-manage:buttons.save')}
      </Button>
    );
  };

  return (
    <div className="bg-background-1 flex h-full flex-col">
      <Formik
        enableReinitialize
        validateOnChange
        validationSchema={schemas.client.maintainClient}
        onSubmit={saveClient}
        initialValues={initialValues}
      >
        {(props) => {
          const { handleSubmit, resetForm } = props;
          resetFormRef.current = resetForm;

          const handleSaveClient = () => {
            if (!client?.id) {
              return;
            }
            handleSubmit();
          };

          return (
            <form className="flex h-full flex-col">
              <TopNavPortal>
                <Heading size={HeadingSize.H3} textColor="text-color-2">
                  {t('client-list:heading')}
                </Heading>
              </TopNavPortal>
              <div className="border-gray-5 h-0 flex-grow overflow-y-auto border-t">
                <div className="p-6 pb-8">
                  <div className="flex justify-between">
                    <div>
                      <div className="flex items-center gap-4">
                        <BreadCrumbBackButton onClick={() => navigate('/clients/')}></BreadCrumbBackButton>
                        <Heading size={HeadingSize.H3} textColor="text-color-2">
                          {t('client-manage:heading')}
                        </Heading>
                      </div>
                      <Heading size={HeadingSize.H4} textColor="text-color-2" className="pt-4">
                        {t('client-manage:details.heading')}
                      </Heading>
                    </div>
                    <div className="flex items-start gap-4">
                      <SaveButton formik={props} handleClick={handleSaveClient} />

                      <Button data-cy="launch-workspace" onClick={() => navigate(`/clients/${client?.id}/home`)}>
                        {t('client-manage:buttons.launch')}
                      </Button>
                    </div>
                  </div>

                  <PageLoader loading={!client}>
                    <ClientManage formik={props} />
                  </PageLoader>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default MaintainClientDtos;
